html * {
  font-family: 'Pretendard', 'sans-serif' !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
body {
  line-height: 1;
  height: 100%;
  min-width: 1170px;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a:hover,
a:active {
  text-decoration: none;
}
/* Safari input style reset */
input,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='search'],
textarea {
  -webkit-border-radius: 0; /* 둥근모서리 */
  -webkit-appearance: none; /* 그림자 */
}
i input {
  padding: 0;
}
input {
  -webkit-appearance: none;
  outline: none;
}
input:disabled {
  opacity: 1;
}

button:active {
  outline: none;
}

button:focus {
  outline: 0;
}
img {
  width: 100%;
  max-width: 100%;
}
img[src=''] {
  content: '';
}

/* ::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  display: none;
} */

button,
a {
  -webkit-tap-highlight-color: transparent;
}

button {
  margin: 0;
}

@media screen and (max-width: 1170px) {
  body {
    overflow-x: auto;
  }
}
